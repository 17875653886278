.about-page {
    font-family: Arial, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .about-header {
    background-color: #9bff91;
    text-align: center;
    padding: 20px 0;
  }
  
  .about-header h1 {
    margin: 0;
    font-size: 2.5em;
  }
  
  .about-header p {
    margin: 10px 0 0;
  }
  
  .about-section {
    text-align: center;
    padding: 20px 0;
    width: 60%;
  }
  
  .about-section h2 {
    font-size: 2em;
  }
  
  