footer {
    background-color: #333;
    color: white;
    padding: 20px;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .contact-icons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .contact-icons a {
    margin: 0 10px;
  }
  
  .contact-icons img {
    width: 24px;
    height: 24px;
  }
  
  @media (min-width: 768px) {
    .footer-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  
    .contact-icons {
      margin-bottom: 0;
    }
  }
  
  
  .copyright-text {
    font-size: 14px;
  }
  