.services-page {
    font-family: Arial, sans-serif;
  }
  
  .services-header {
    background-color: #9bff91;
    text-align: center;
    padding: 40px 0;
  }
  
  .services-header h1 {
    margin: 0;
    font-size: 2.5em;
  }
  
  .services-header p {
    margin: 10px 0 0;
  }
  
  .our-mission {
    text-align: center;
    padding: 40px 0;
  }
  
  .our-mission h2 {
    font-size: 2em;
  }
  
  .our-mission span {
    color:rgb(163, 122, 8);
  }
  
  .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 40px 0;
  }
  
  .service {
    background-color: #f5f5f5;
    width: 30%;
    margin: 10px 0;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }
  
  .service h3 {
    margin-top: 0;
  }
  
  .pricing-plan {
    text-align: center;
  padding: 20px;
  }
  
  .plans {
    display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  }
  
  .plan {
    background-color: #f5f5f5;
    padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 300px;
  max-width: 31%;
  box-sizing: border-box;
    border-radius: 5px;
    text-align: right;
  }
  .plan h2{
    font-size: 1.5em;
    text-align: center;
    color:rgb(163, 122, 8);
  }
  .plan .price {
    font-size: 1.5em;
    color: rgb(163, 122, 8);
  }
  
  .plan ul {
    list-style: none;
    padding: 0;
  }
  
  .plan ul li {
    margin: 10px 0;
  }
  
  .plan button {
    background-color: #45a629;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .plan button:hover {
    background-color: #369f1f;
  }
  
  .plan h3 {
    font-size: 1.2em;
    color: #333;
  }
  
  .plan p {
    font-size: 1em;
    line-height: 1.6;
    color: #666;
    text-align: right;
  }
  
  @media (max-width: 1024px) {
    .plans {
      flex-direction: column;
      align-items: center;
    }
  
    .plan {
      max-width: 100%;
    }
  }
  