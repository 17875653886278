.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282c34;
  padding: 20px;
  color: white;
  direction: rtl;
  background: url('./assets/bg-10.png') no-repeat center center; 
  background-size: cover;
}
.studio{
  color: rgb(163, 122, 8);
    font-size: 40px;
    background-color: #333;
    opacity: 75%;
    padding: 10px;
    border-radius: 5px;
}
.sub-header{
  font-size: 20px;
    color: rgb(230, 227, 221);
    background: #333;
    text-align: center;
    opacity: 75%;
    border-radius: 5px;
    padding: 10px;
}
nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  color: #e0e0e0;
  text-decoration: none;
  font-weight: bold;
  font-size: 20px;
}

nav ul li a:hover {
  text-decoration: underline;
}
/* --------------------------------- */

.nav-container {
  background-color: #333;
  opacity: 75%;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.nav-header {
  display: flex;
  justify-content: flex-end;
}

.nav-toggle {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  display: none;
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
}

.nav-menu li {
  margin: 0 15px;
}

.nav-menu a {
  color: white;
  text-decoration: none;
}

@media (max-width: 768px) {
  .nav-toggle {
    display: block;
  }

  .nav-menu {
    flex-direction: column;
    display: none;
    width: 100%;
  }

  .nav-open {
    display: flex;
  }

  .nav-menu li {
    margin: 10px 0;
    text-align: center;
  }
}

/* ---------------------------------*/
main {
  padding: 20px;
  flex: 1;
}

.chatbot {
  position: fixed;
  bottom: 70px;
  right: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: right;
  direction: rtl;
  width: 300px;
  height: 500px;
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}

.chat-window {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
}

.message.user {
  background-color: #dcf8c6;
  align-self: flex-end;
}

.message.bot {
  background-color: #ececec;
  align-self: flex-start;
}

.input-area {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #ccc;
  text-align: right;
  direction: rtl;
}

.input-area input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

.input-area button {
  padding: 10px;
  margin: 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.input-area .dropzone {
  border: 2px dashed #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

