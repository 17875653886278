.contact-page {
    font-family: Arial, sans-serif;
  }
  
  .contact-header {
    background-color: #9bff91;
    text-align: center;
    padding: 40px 0;
  }
  
  .contact-header h1 {
    margin: 0;
    font-size: 2.5em;
  }
  
  .contact-header p {
    margin: 10px 0 0;
  }
  
  .contact-section {
    text-align: center;
    padding: 40px 0;
  }
  
  .contact-section h2 {
    font-size: 2em;
  }
  
  .contact-details {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .contact-detail {
    flex: 1;
    min-width: 200px;
    margin: 10px;
    text-align: center;
  }
  
  .contact-detail h3 {
    font-size: 1.5em;
    color: #333;
  }
  
  .contact-detail p {
    font-size: 1em;
    color: #666;
  }
  
  @media (max-width: 768px) {
    .contact-details {
      flex-direction: column;
      align-items: center;
    }
    
    .contact-detail {
      min-width: unset;
      width: 100%;
      text-align: center;
    }
  }
  
  
  