.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  text-align: right;
  direction: rtl;
}

.chatbot-toggle {
  background-color: #45a629;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.chatbot-toggle:hover {
  background-color: #369f1f;
}

.chatbot {
  background-color: white;
  width: 300px;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}
.choices button{
  margin: 5px;
}
.chat-window {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
}

.message {
  margin: 5px 0;
}

.message.user {
  text-align: right;
}

.input-area {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.input-area input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: right;
  direction: rtl;
}

.input-area button {
  background-color: #45a629;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.input-area button:hover {
  background-color: #369f1f;
}
