
.contact-form-container {
  direction: rtl;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.contact-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
}

.form-title {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #333;
  text-align: right;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 1em;
  color: #333;
  text-align: right;
}

.form-input,
.form-textarea {
  width: calc(100% - 20px);
  margin-bottom: 20px;
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-textarea {
  height: 100px;
  resize: none;
}

.form-checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.form-checkbox {
  margin-left: 10px;
}

.form-checkbox-label {
  font-size: 1em;
  color: #333;
}

.form-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.form-button:hover {
  background-color: #555;
}
.contact-sub{
  text-align: center;
  padding: 10px;
  font-size: 1em;
  color: #333;
  
}