.home-page {
  font-family: Arial, sans-serif;
  direction: rtl;
}

.home-header {
  background-color: #9bff91;
  text-align: center;
  padding: 40px 0;
}

.home-header h1 {
  margin: 0;
  font-size: 2.5em;
}

.home-header p {
  margin: 10px 0 0;
}

.home-about,
.home-services,
.home-contact {
  text-align: center;
  padding: 20px 0;
}

.home-about h2,
.home-services h2,
.home-contact h2 {
  font-size: 2em;
}
.home-services {
  text-align: center;
  align-items: center !important;
}
.center-width {
  width: 60%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.service-div {
  background-color: #f5f5f5;
  width: 700px;
  margin: 10px !important;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

@media (max-width: 1024px) {
  .service-div {
    background-color: #f5f5f5;
    width: 90%;
    padding: 20px;
    margin: 10px !important;
    border-radius: 5px;
    text-align: center;
  }
}
.service-div h3 {
  margin-top: 0;
}

.centered-section {
  text-align: center; /* Center text inside section */ /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centered-content {
  display: inline-block; /* Ensures the div takes only as much width as its content */
  margin: auto; /* Auto margins to center the div */
}
.home-page .header-sub {
  text-align: center;
  color: rgb(163, 122, 8);
  font-size: 2em;
}
.auto-image {
  background: url("../assets/bg-6.png") no-repeat center center;
  background-size: cover;
  color: #ffffff;
}
.ser-auto {
  background-color: black;
  opacity: 70%;
  color: #fff;
  font-size: large;
}
.adv-image {
  background: url("../assets/bg-7.png") no-repeat center center;
  background-size: cover;
  color: #ffffff;
}
.learn-image {
  background: url("../assets/bg-8.png") no-repeat center center;
  background-size: cover;
  color: #ffffff;
}
